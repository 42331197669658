import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


// import {GCol, GContainer} from "../components/grid/grid";
//import {LocationCard, Locations} from "../components/locations/locations";
import {graphql, useStaticQuery} from "gatsby";
import {Card, Cards} from "../components/cards/cards";
import berlin from "../images/locations/berlin.png";
import moscow from "../images/locations/moscow.jpeg";
import sanFrancisco from "../images/locations/san-francisco.png";
import voronezh from "../images/locations/voronezh.png";

const ContactsPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            version
          }
        }
      }
    `
  )

  // return (
  //   <Layout>
  //     <Seo lang={'en'} title="Evrone contacts and offices" description={'Contact us to get more information about our vacancies'} />
  //     <div className={"block"}>
  //       <h1 className="textWrapper tW_bg text-t1">
  //         На любой вопрос про нас и вакансии ответит наш HR — Руслан Саврасов:<br/>
  //         <a className={'textLink'} href={'mailto:sarvarov.ruslan@evrone.com'}>sarvarov.ruslan@evrone.com</a>
  //       </h1>
  //
  //       <Cards type={1}>
  //         <Card
  //           type={4}
  //           pict={<StaticImage src={"../images/locations/berlin.png"} alt={''} aspectRatio={1.78} layout="fullWidth"/>}
  //           title={'Berlin'}
  //           desc={'Potsdamer Platz - Kemperplatz 1 BE 10785, Berlin WeWork Residents'}
  //           link={'https://www.wework.com/ru-RU/buildings/sony-center--berlin/virtual-tour'}
  //         />
  //
  //         <Card
  //           type={4}
  //           pict={<StaticImage src={"../images/locations/san-francisco.png"} alt={''} aspectRatio={1.78} layout="fullWidth"/>}
  //           title={'San Francisco'}
  //           desc={'44 Tehama St. CA 94105, San Francisco'}
  //         />
  //       </Cards>
  //     </div>
  //   </Layout>
  // )
  if (site.siteMetadata.version === 'ru') {
    return (
      <Layout>
        <Seo lang={'ru'} title="Контакты" description={"Как с нами связаться"}/>
        <div className={"block"}>
          <h1 className="textWrapper tW_bg text-t1">
            На любой вопрос про нас и вакансии ответит наш HR — Анастасия Синельникова:<br/>
            <a className={'textLink'} href={'mailto:a.sinelnikova@evrone.com'}>a.sinelnikova@evrone.com</a>
          </h1>

          <Cards type={1}>
            <Card
              type={4}
              pict={<StaticImage src={"../images/locations/moscow.jpeg"} alt={''} aspectRatio={1.78} layout="fullWidth"/>}
              title={'Москва'}
              desc={'ул. Большая Якиманка, д. 26, MOS 119180, Москва, Резиденты WeWork'}
              link={{href:'https://www.wework.com/ru-RU/buildings/ulitsa-bol-shaya-yakimanka-26--moscow/virtual-tour', name: 'Смотреть в 3d'}}
            />
            <Card
              type={4}
              pict={<StaticImage src={"../images/locations/berlin.png"} alt={''} aspectRatio={1.78} layout="fullWidth"/>}
              title={'Берлин'}
              desc={'Potsdamer Platz - Kemperplatz 1 BE 10785, Berlin, Резиденты WeWork'}
              link={{href:'https://www.wework.com/ru-RU/buildings/sony-center--berlin/virtual-tour', name: 'Смотреть в 3d'}}
            />

            <Card
              type={4}
              pict={<StaticImage src={"../images/locations/san-francisco.png"} alt={''} aspectRatio={1.78} layout="fullWidth"/>}
              title={'Сан-Франциско'}
              desc={'44 Tehama St. CA 94105, San Francisco'}
            />

            <Card
              type={4}
              pict={<StaticImage src={"../images/locations/voronezh.png"} alt={''} aspectRatio={1.78} layout="fullWidth"/>}
              title={'Воронеж'}
              desc={'Адрес: ул. Короленко, д.5, 394036, Воронеж'}
            />
          </Cards>
        </div>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <Seo lang={'en'} title="Evrone contacts and offices" description={'Contact us to get more information about our vacancies'} />
        <div className={"block"}>
          <h1 className="textWrapper tW_bg text-t1">
            You can ask any question by writing an email to Anastasia Sinelnikova, our HR:<br/>
            <a className={'textLink'} href={'mailto:a.sinelnikova@evrone.com'}>a.sinelnikova@evrone.com</a>
          </h1>

          <Cards type={1}>
            <Card
              type={4}
              pict={<StaticImage src={"../images/locations/berlin.png"} alt={'Berlin'} aspectRatio={1.78} layout="fullWidth"/>}
              title={'Berlin'}
              desc={'Potsdamer Platz - Kemperplatz 1 BE 10785, Berlin WeWork Residents'}
              link={{href:'https://www.wework.com/ru-RU/buildings/sony-center--berlin/virtual-tour', name: 'View in 3D'}}
            />

            <Card
              type={4}
              pict={<StaticImage src={"../images/locations/san-francisco.png"} alt={'San Francisco'} aspectRatio={1.78} layout="fullWidth"/>}
              title={'San Francisco'}
              desc={'44 Tehama St. CA 94105, San Francisco'}
            />
          </Cards>
        </div>
      </Layout>
    )
  }

}

export default ContactsPage
